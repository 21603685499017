<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <!-- <router-link :to="{path:'/inicio'}">Dashboard</router-link> -->
            <router-link :to="{path: ldasbb}">Dashboard</router-link>
           
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; Coded with
        <i class="fa fa-heart heart"></i> by <a href="https://www.datatools.mx" target="_blank" rel="noopener">DataTools</a>.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data () {
      return {
        ldasbb: localStorage.getItem('dashbb') || '/dashb/dashpini'
        //ldasbb:'/dashb/dashboard'
        //ldasbb:'/dashb/dashpopera'
        // path1:'/dashb/dashboard'
        
      }
    }
  }

</script>
<style>

</style>
