import axios from 'axios';

export const HTTP = axios.create({
  
  // MAU PROD
  //baseURL: `https://201.168.125.94/authf`,
  //baseURL: `https://empleado.datatools.mx/authf`,

  //baseURL: `https://sfhtua.servicestools.com/`,
  //baseURL: `http://sinapsis2.dyndns.org/authf/`,
  baseURL: `http://auth.datools.net/`,

  //axios.defaults.baseURL="http://sfhtua.servicestools.com/";

  
  // MAU DEV
  //baseURL: `https://localhost/authf`,

})
