<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <!-- <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/admin/overview'}">
        </sidebar-item> -->

        <sidebar-item :link="{name: 'Catalogos', icon: 'nc-icon nc-vector'}" v-if="validaform('F1,') =='true'">
          
          <sidebar-item :link="{name: 'Convenios', path: '/catalogos/convenios'}" v-if="validaform('F1-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Clientes', path: '/catalogos/clientes'}" v-if="validaform('F1-2,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Cartera', path: '/catalogos/cartera'}" v-if="validaform('F1-3,') =='true'"></sidebar-item>
          
        </sidebar-item>

        <sidebar-item :link="{name: 'RENAPO', icon: 'nc-icon nc-app'}" v-if="validaform('F4,') =='true'">
          <sidebar-item :link="{name: 'Validar CURPs', path: '/tools/valcurps'}" v-if="validaform('F4-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Obtener CURPs', path: '/tools/gencurp'}" v-if="validaform('F4-2,') =='true'"></sidebar-item>
          
        </sidebar-item>

        <sidebar-item :link="{name: 'Sección 50', icon: 'nc-icon nc-app'}" v-if="validaform('F2,') =='true'">
          
          <sidebar-item :link="{name: 'Dashboard', path: '/operacion/dashopera'}" v-if="validaform('F2-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Estado de Cuenta', path: '/operacion/edocta'}" v-if="validaform('F2-2,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Cobranza de Listados', path: '/operacion/coblist'}" v-if="validaform('F2-3,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Terminaron Cuenta UL', path: '/operacion/termcuenta'}" v-if="validaform('F2-4,') =='true'"></sidebar-item>

          <sidebar-item :link="{name: 'Escuelas'}" v-if="validaform('F2-5,') =='true'">
            <sidebar-item :link="{name: 'Distribución', path: '/operacion/escmunicipio'}" v-if="validaform('F2-5,') =='true'"></sidebar-item>
            <sidebar-item :link="{name: 'Empleado Escuela', path: '/operacion/emplescuela'}" v-if="validaform('F2-6,') =='true'"></sidebar-item>
          </sidebar-item>
            
          <!-- <sidebar-item :link="{name: 'Pruebas', path: '/operacion/pruebas'}"></sidebar-item>  -->
          
        </sidebar-item>

        <sidebar-item :link="{name: 'Fone', icon: 'nc-icon nc-app'}" v-if="validaform('F3,') =='true'">
          <sidebar-item :link="{name: 'Distribución', path: '/fone/empfeddist'}" v-if="validaform('F3-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Prospectos', path: '/fone/prosfeddist'}" v-if="validaform('F3-2,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Prospectos Listado', path: '/fone/prosfeddistlist'}" v-if="validaform('F3-3,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Mapa Prospectos', path: '/fone/mapprosfeddistlist'}" v-if="validaform('F3-4,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar x CURP', path: '/fone/empfedxcurp'}" v-if="validaform('F3-5,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar x CURP Lista', path: '/fone/empfedxcurplist'}" v-if="validaform('F3-6,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar x CURP Historia', path: '/fone/empfedhisxcurp'}" v-if="validaform('F3-8,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar x CURP Historia Neto', path: '/fone/empfedhisnetxcurp'}" v-if="validaform('F3-9,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Empleados', path: '/fone/empfed'}" v-if="validaform('F3-7,') =='true'"></sidebar-item>
          
          
        </sidebar-item>

        <sidebar-item :link="{name: 'Gobierno Sinaloa', icon: 'nc-icon nc-app'}" v-if="validaform('F6,') =='true'">
          <sidebar-item :link="{name: 'Dashboard', path: '/gobsinaloa/dashgobsinaloa'}" v-if="validaform('F6-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar x Nombre', path: '/gobsinaloa/buscargobsinaloa'}" v-if="validaform('F6-2,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Cobranza cero', path: '/gobsinaloa/cobcerogobsinaloa'}" v-if="validaform('F6-3,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Cobranza parcial', path: '/gobsinaloa/cobparcialgobsinaloa'}" v-if="validaform('F6-4,') =='true'"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{name: 'Gob Estado México', icon: 'nc-icon nc-app'}" v-if="validaform('F7,') =='true'">
          <sidebar-item :link="{name: 'Dashboard', path: '/gem/dashgem'}" v-if="validaform('F7-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Prospectos', path: '/gem/prospectgem'}" v-if="validaform('F7-2,') =='true'"></sidebar-item> 
          <sidebar-item :link="{name: 'Buscar', path: '/gem/buscarempgem'}" v-if="validaform('F7-3,') =='true'"></sidebar-item> 
        </sidebar-item>

        <sidebar-item :link="{name: 'Snte37', icon: 'nc-icon nc-app'}" v-if="validaform('F8,') =='true'">
          <sidebar-item :link="{name: 'Dashboard', path: '/snte37/dashsnte37'}" v-if="validaform('F8-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Prospectos', path: '/snte37/prospectsnte37'}" v-if="validaform('F8-2,') =='true'"></sidebar-item>          
          <sidebar-item :link="{name: 'Buscar', path: '/snte37/buscarempsnte37'}" v-if="validaform('F8-3,') =='true'"></sidebar-item>  
        </sidebar-item>

        <sidebar-item :link="{name: 'Gob Baja California', icon: 'nc-icon nc-app'}" v-if="validaform('F9,') =='true'">
          <sidebar-item :link="{name: 'Dashboard', path: '/gbc/dashgbc'}" v-if="validaform('F9-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar', path: '/gbc/buscarempgbc'}" v-if="validaform('F9-2,') =='true'"></sidebar-item>          
        </sidebar-item>

        <sidebar-item :link="{name: 'Premium', icon: 'nc-icon nc-single-copy-04'}" v-if="validaform('F5,') =='true'">
          
          <sidebar-item :link="{name: 'Movto Secc 50', path: '/avanzado/movimientose'}" v-if="validaform('F5-1,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Información Issste', path: '/avanzado/issteinfo'}" v-if="validaform('F5-2,') =='true'"></sidebar-item>
          <sidebar-item :link="{name: 'Buscar Persona', path: '/avanzado/buscarpernom'}" v-if="validaform('F5-3,') =='true'"></sidebar-item>

        </sidebar-item>
        
        <!-- <sidebar-item :link="{name: 'Components', icon: 'nc-icon nc-app'}">
          <sidebar-item :link="{name: 'Dashboard', path: '/admin/overview'}"></sidebar-item>
          <sidebar-item :link="{name: 'Buttons', path: '/components/buttons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Grid System', path: '/components/grid-system'}"></sidebar-item>
          <sidebar-item :link="{name: 'Panels', path: '/components/panels'}"></sidebar-item>
          <sidebar-item :link="{name: 'Sweet Alert', path: '/components/sweet-alert'}"></sidebar-item>
          <sidebar-item :link="{name: 'Notifications', path: '/components/notifications'}"></sidebar-item>
          <sidebar-item :link="{name: 'Icons', path: '/components/icons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Typography', path: '/components/typography'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Forms', icon: 'nc-icon nc-notes'}">
          <sidebar-item :link="{name: 'Regular Forms', path: '/forms/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Forms', path: '/forms/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Validation Forms', path: '/forms/validation'}"></sidebar-item>
          <sidebar-item :link="{name: 'Wizard', path: '/forms/wizard'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Tables', icon: 'nc-icon nc-paper-2'}">
          <sidebar-item :link="{name: 'Regular Tables', path: '/table-list/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Tables', path: '/table-list/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Paginated Tables', path: '/table-list/paginated'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Maps', icon: 'nc-icon nc-pin-3'}">
          <sidebar-item :link="{name: 'Google Maps', path: '/maps/google'}"></sidebar-item>
          <sidebar-item :link="{name: 'Full Screen Maps', path: '/maps/full-screen'}"></sidebar-item>
          <sidebar-item :link="{name: 'Vector Maps', path: '/maps/vector-map'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Charts', icon: 'nc-icon nc-chart-bar-32', path: '/admin/charts'}"></sidebar-item>
        <sidebar-item :link="{name: 'Calendar', icon: 'nc-icon nc-single-copy-04', path: '/admin/calendar'}"></sidebar-item>
        <sidebar-item :link="{name: 'Pages', icon: 'nc-icon nc-puzzle-10'}">
          <sidebar-item :link="{name: 'User Page', path: '/pages/user'}"></sidebar-item>
          <sidebar-item :link="{name: 'Login Page', path: '/login'}"></sidebar-item>
          <sidebar-item :link="{name: 'Register', path: '/register'}"></sidebar-item>
          <sidebar-item :link="{name: 'Lock Screen Page', path: '/lock'}"></sidebar-item>
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <Content @click.native="toggleSidebar">

      </Content>
 
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import Content from './Content.vue'
  import MobileMenu from './Extra/MobileMenu.vue'
  import UserMenu from './Extra/UserMenu.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import EventBus from '../../../core/utils/eventbus';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }
  
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      Content,
      MobileMenu,
      UserMenu
    },
    data () {
      return {
        mostrar: false,
        lbusuario: localStorage.getItem('key'),
        formas : localStorage.getItem('forma'),
      }
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },

      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      },

      validaform(forma){
        let formavalida = "false";

        // if (this.lbusuario=='osoriacm@gmail.com'){
        //     this.formas = 'F1,F1-1,F1-2,F1-3,F2,F2-1,F2-2,F2-3,F2-4,F2-5,F2-6,F3,F3-1,F3-2,F3-3,F3-4,F3-5,F3-6,F3-7,F3-8,F4,F4-1,F4-2,F5,F5-1,F5-2,F6,F6-1,F6-2,F6-3,F6-4,'
        // }
        // if (this.lbusuario=='dante.teytud@fmexi.com'){
        //     this.formas = 'F3,F3-1,F3-2,F3-3,F3-4,F3-5,F3-6,F3-7,F3-8,F6,F6-1,F6-2,F6-3,F6-4,'
        // }
        // if (this.lbusuario=='veronica.reyna@mexi.com'){
        //     this.formas = 'F1,F1-1,F1-2,F1-3,F2,F2-1,F2-2,F2-3,F2-4,F2-5,F2-6,F3,F3-1,F3-2,F3-3,F3-4,F3-5,F3-6,F3-7,F3-8,F4,F4-1,F4-2,F5,F5-1,F5-2,F6,F6-1,F6-2,F6-3,F6-4,'
        // }
        // if (this.lbusuario=='elena.gutierrez@fmexi.com'){
        //     this.formas = 'F2,F2-1,F2-2,F2-3,F2-4,F2-5,F2-6,F3,F3-5,F4,F4-1,F4-2,F6,F6-2,F6-3,F6-4,'
        // }

        let posicion = this.formas.indexOf(forma);

        if (posicion !== -1){
          formavalida = "true"; 
        }
        
        return formavalida;
      },
      logout() {
        // this.signOut().then(() => {
        //   this.$router.push('/login');
        // });
      }
    },
    created() {
        EventBus.$on('loginTimeOut',()=>{
        this.logout()
      })
      // const color = getThemeColor();
      // this.isDarkActive = color.indexOf("dark") > -1;
      // this.setcommads();
    },
    mounted() {
      this.mformas();
      this.initScrollbar();
    }

  }

</script>
